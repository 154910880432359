const data = [
  {
    name: "Início",
    path: null,
    children: [
      { name: "Credores", icon: "mdi-account-group-outline", route: "/usuarios", path: "User" },
    ],
  },
  {
    name: "Gestão",
    path: "Manager",
    children: [
      { name: "Solicitação de Documentos", icon: "mdi-account-file-outline", route: "/solicitacao-documentos", path: "Client" },
      { name: "Hab/Div", icon: "mdi-sync-alert", route: "/divergencias", path: "Event" },
    ],
  },
];

export default [...data];
